import { gql } from "@apollo/client";
import {
  MentorDashAssignmentRole,
  MentorDashboardEngagementTable_DashboardEngagementFragment,
  MentorDashboardEngagementTable_SessionAssignmentRowFragment,
} from "@generated/graphql";
import { CohortInfoLine } from "@shared/CohortInfoLine";
import { JoinMeetingButton } from "@shared/JoinMeetingButton";
import { OpenSessionReportButton } from "@shared/SessionReports";
import { Routes } from "@utils/routes";
import { CohortDetailsOpenIcon } from "components/cohorts/CohortDetailsModal/components/CohortDetailsOpenIcon";
import { Button, Icon, IconText, Link, Tooltip } from "components/shared";

CohortSessionDetailsCell.fragments = {
  sessionAssignmentRow: gql`
    fragment CohortSessionDetailsCell_SessionAssignmentRow on MentorDashSessionAssignmentRow {
      subject
      cohortId
      staffRole
      cohortName
      subSubject
      cohortSessionId
      sessionReportId
      instructionLevel
      cohortMeetingRoom
      cohortPublicReferenceId
      hasSessionReportConcerns
      cohortSessionStartDateTime
    }
  `,
};

type Props = {
  timeslotString: string;
  row: MentorDashboardEngagementTable_SessionAssignmentRowFragment;
  engagement: MentorDashboardEngagementTable_DashboardEngagementFragment;
};

export function CohortSessionDetailsCell({
  engagement: { engagementId },
  timeslotString,
  row: {
    subject,
    cohortId,
    staffRole,
    cohortName,
    subSubject,
    cohortSessionId,
    sessionReportId,
    instructionLevel,
    hasSessionReportConcerns,
    cohortSessionStartDateTime,
  },
}: Props) {
  return staffRole === MentorDashAssignmentRole.Tt ? (
    <>
      <div className="flex flex-1 items-center justify-start gap-2 px-3">
        <Tooltip
          content="View Attendance on Engagements Dashboard"
          tooltipProps={{ delayShow: 500, place: "right" }}
        >
          <IconText
            link
            className="mr-[10px]"
            icon="cohortFill"
            child={
              <Link
                customColor
                route={Routes.engagementsDashboard}
                routeProps={[
                  `engagementId=${engagementId}&timeslot=${timeslotString}`,
                ]}
              >
                {cohortName}
              </Link>
            }
          />
        </Tooltip>

        <CohortInfoLine
          instructionLevel={instructionLevel ?? undefined}
          subjectCombos={subject ? [{ subject, subSubject }] : []}
        />

        <CohortDetailsOpenIcon cohortId={cohortId ?? "0"} />

        <OpenSessionReportButton
          cohortSessionId={cohortSessionId}
          hasConcerns={hasSessionReportConcerns}
          cohortSessionReportId={sessionReportId}
          cohortSessionStartTime={new Date(
            cohortSessionStartDateTime
          ).getTime()}
        />

        <Tooltip content="Join Meeting" tooltipProps={{ place: "right" }}>
          <JoinMeetingButton isHost={true} cohortId={cohortId}>
            <Button className="w-fit h-[21px]! px-[6px]">
              <Icon
                icon="zoom"
                color="text-white"
                className="w-[16px] h-[16px]"
              />
            </Button>
          </JoinMeetingButton>
        </Tooltip>
      </div>
    </>
  ) : (
    <div className="flex flex-1 items-center justify-start px-3">
      <Link
        customColor
        route={Routes.engagementsDashboard}
        routeProps={[
          `engagementId=${engagementId}&isSubstitute=true&timeslot=${timeslotString}`,
        ]}
      >
        <Tooltip
          tooltipProps={{ place: "right" }}
          content="View Engagement Shift Details"
        >
          <Icon icon="timeLapse" size={5} />
        </Tooltip>
      </Link>
    </div>
  );
}
