import {
  ISODate,
  normalizeToLocalISODate,
  normalizeToZonedISODate,
} from "@utils/dateTime";
import { getInputStyle } from "@utils/styleStrings";
import { DatePicker } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect } from "react";

type Props = {
  staticWidth?: boolean;
  selectedISODayET?: ISODate;
  setSelectedISODayET: (date: ISODate) => void;
};

export const DayDatePicker = ({
  selectedISODayET,
  staticWidth = false,
  setSelectedISODayET,
}: Props) => {
  const currentEtISODay = normalizeToZonedISODate(new Date());
  const isCustomSelectedDate = selectedISODayET !== currentEtISODay;

  useEffect(() => {
    if (!selectedISODayET) setSelectedISODayET(currentEtISODay);
  }, [currentEtISODay, selectedISODayET, setSelectedISODayET]);

  return (
    <div
      className={clsx(
        "flex items-center gap-x-3 h-14",
        "px-4 rounded-[12px] shadow-options-outer",
        staticWidth ? "w-[250px]" : "w-full lg:w-[250px]",
        isCustomSelectedDate ? "bg-slate-600" : "bg-white"
      )}
    >
      <label
        className={clsx(
          "text-sm font-medium whitespace-nowrap overflow-hidden",
          isCustomSelectedDate ? "text-white" : "text-slate-700"
        )}
      >
        {isCustomSelectedDate ? "Custom" : "Today"}
      </label>

      <DatePicker
        showNow={false}
        className="flex-1"
        format={"MM/DD/YYYY"}
        style={getInputStyle}
        defaultValue={dayjs(selectedISODayET)}
        onChange={(date) => {
          if (date) setSelectedISODayET(normalizeToLocalISODate(date.toDate()));
        }}
      />
    </div>
  );
};
