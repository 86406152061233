import clsx from "clsx";
import { useMemo, useRef } from "react";
import { ProgressBarIndicator } from "./components/ProgressBarIndicator";

type Props = {
  progress: number;
  progressLabel?: string;
  subProgress?: number;
  subProgressLabel?: string;
  showIndicators?: boolean;
};

export const ProgressBar = ({
  progress,
  subProgress,
  progressLabel = "Completed",
  subProgressLabel = "In Progress",
  showIndicators = false,
}: Props) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const subProgressBarRef = useRef<HTMLDivElement>(null);
  const hasSubProgress = Boolean(subProgress && subProgress > 0);
  const progressWidth = progressBarRef.current?.clientWidth ?? 0;
  const subProgressWidth = subProgressBarRef.current?.clientWidth ?? 0;

  const { progressLeft, subProgressLeft } = useMemo(
    () => ({
      progressLeft: progressWidth / 2 - 35,
      subProgressLeft: progressWidth + subProgressWidth / 2 - 49,
    }),
    [progressWidth, subProgressWidth]
  );

  const renderProgressBarDecorations = (progress: number) =>
    progress > 10 ? (
      <>
        <div className="flex w-full gap-x-1">
          <div className="h-[5px] w-[33%] rounded-full bg-white opacity-[0.15]" />
          <div className="h-[5px] w-[7px] rounded-full bg-white opacity-[0.15]" />
        </div>
        <div className="flex gap-x-1 self-end">
          <div className="h-[5px] w-[7px] rounded-full bg-white opacity-[0.1]" />
          <div className="h-[5px] w-[20px] rounded-full bg-white opacity-[0.1]" />
        </div>
      </>
    ) : (
      <div
        className={clsx(
          "absolute top-[4px] left-[8px] h-[5px] w-[6px]",
          "rounded-full bg-white opacity-[0.15]"
        )}
      />
    );

  const renderProgressIndicator = (sub: boolean) =>
    !sub || hasSubProgress ? (
      <div
        className={clsx(
          "absolute bottom-[22px] w-[70px] h-[70px]",
          "transition-opacity duration-500 z-100 hover:z-400"
        )}
        style={{
          opacity: showIndicators ? 1 : 0,
          left: `${sub ? subProgressLeft : progressLeft}px`,
        }}
      >
        <ProgressBarIndicator>
          <div className="flex flex-col gap-y-0 leading-[14px] text-[16px]">
            {sub ? subProgress : progress}%
            <span className="text-[8px]">
              {sub ? subProgressLabel : progressLabel}
            </span>
          </div>
        </ProgressBarIndicator>
      </div>
    ) : null;

  const renderProgressBar = (sub: boolean) =>
    !sub || hasSubProgress ? (
      <div
        ref={sub ? subProgressBarRef : progressBarRef}
        className={clsx(
          "flex relative justify-start items-start h-full rounded-full",
          "shadow-inner-progress pt-[4px] pb-[7px] px-[13px]",
          "transition-width duration-500 max-w-full",
          sub ? "bg-amber-600 z-10" : "bg-blue-800 z-20 mr-[-26px]"
        )}
        style={{ width: sub ? `calc(${subProgress}% + 26px)` : `${progress}%` }}
      >
        {renderProgressBarDecorations(sub ? subProgress ?? 0 : progress)}
      </div>
    ) : null;

  return (
    <div
      className={clsx(
        "flex flex-center w-full bg-white",
        "shadow-outer-progress rounded-full h-[38px] p-[5px]"
      )}
    >
      <div
        className={clsx(
          "flex relative justify-start items-center h-full bg-indigo-50/50",
          "w-full shadow-full-progress border border-gray-200 rounded-full"
        )}
      >
        {renderProgressIndicator(false)}
        {renderProgressBar(false)}
        {renderProgressIndicator(true)}
        {renderProgressBar(true)}
      </div>
    </div>
  );
};
