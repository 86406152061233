import { gql } from "@apollo/client";
import {
  MentorDashboardEngagementTable_DashboardEngagementFragment,
  MentorDashboardEngagementTable_SessionAssignmentRowFragment,
} from "@generated/graphql";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { useLayout } from "contexts/LayoutProvider";
import { useMemo } from "react";
import {
  AttendanceCell,
  CohortSessionDetailsCell,
  HostPresenceCell,
  RoleCell,
  StaffCell,
} from "./components/cells";
import { StaffAttendanceTableHeader } from "./components/StaffAttendanceTableHeader";
import { StaffAttendanceTableRow } from "./components/StaffAttendanceTableRow";
import { getColWidths } from "./utils";

MentorDashboardEngagementTable.fragments = {
  engagement: gql`
    fragment MentorDashboardEngagementTable_DashboardEngagement on MentorDashEngagement {
      id
      name
      engagementId
      videoProvider
      tracksLiveAttendance
    }
  `,
  sessionAssignmentRow: gql`
    fragment MentorDashboardEngagementTable_SessionAssignmentRow on MentorDashSessionAssignmentRow {
      id
      staffName
      staffRole
      staffPronouns
      substituteName
      ...RoleCell_SessionAssignmentRow
      ...StaffCell_SessionAssignmentRow
      ...AttendanceCell_SessionAssignmentRow
      ...HostPresenceCell_SessionAssignmentRow
      ...CohortSessionDetailsCell_SessionAssignmentRow
    }
    ${RoleCell.fragments.sessionAssignmentRow}
    ${StaffCell.fragments.sessionAssignmentRow}
    ${AttendanceCell.fragments.sessionAssignmentRow}
    ${HostPresenceCell.fragments.sessionAssignmentRow}
    ${CohortSessionDetailsCell.fragments.sessionAssignmentRow}
  `,
};

type Props = {
  timeslotString: string;
  rows: MentorDashboardEngagementTable_SessionAssignmentRowFragment[];
  engagement: MentorDashboardEngagementTable_DashboardEngagementFragment;
};

export function MentorDashboardEngagementTable({
  rows,
  engagement,
  timeslotString,
}: Props) {
  const { screenWidth, isLessThan3XlScreen } = useLayout();

  const { staffColWidth, attendanceColWidth } = useMemo(() => {
    return getColWidths(screenWidth, isLessThan3XlScreen, rows);
  }, [rows, isLessThan3XlScreen, screenWidth]);

  return (
    <div
      className={clsx(
        "block w-full h-full overflow-x-auto",
        getScrollbarStyle("barOnly")
      )}
    >
      <div
        className={clsx(
          "flex flex-col min-w-full w-fit border rounded-lg",
          "border-gray-300 divide-y divide-gray-300"
        )}
      >
        <StaffAttendanceTableHeader
          attendanceColWidth={attendanceColWidth}
          staffColWidth={staffColWidth}
        />

        {rows.map((row) => (
          <StaffAttendanceTableRow
            row={row}
            key={row.id}
            engagement={engagement}
            staffColWidth={staffColWidth}
            timeslotString={timeslotString}
            attendanceColWidth={attendanceColWidth}
          />
        ))}
      </div>
    </div>
  );
}
