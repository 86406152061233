import { gql } from "@apollo/client";
import { TeacherTutorCell_TutorDashboardEventFragment } from "@generated/graphql";
import { tableHeaderStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { CellProps, Column } from "react-table";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

teacherTutorCell.fragments = {
  tutorDashboardEvent: gql`
    fragment TeacherTutorCell_TutorDashboardEvent on TutorDashboardEvent {
      teacherTutorName
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  TeacherTutorCell_TutorDashboardEventFragment,
  unknown,
  unknown
>;

export function teacherTutorCell<D extends EventDetails>(): Column<D> {
  return {
    id: "teacherTutorName",
    Header: () => <span className={tableHeaderStyle()}>Teacher Tutor</span>,
    Cell: ({ row: { original } }: CellProps<D>) => (
      <div
        className={clsx(
          "flex flex-center",
          original.teacherTutorName === "Unassigned"
            ? "text-rose-600 font-semibold"
            : "text-gray-700 font-medium"
        )}
      >
        {original.teacherTutorName}
      </div>
    ),
  };
}
