import { gql } from "@apollo/client";
import {
  InstructionalSupportTab_TutorDashboardCohortFragment,
  InstructionalSupportTab_TutorDashboardEngagementFragment,
  InstructionalSupportTab_TutorDashboardEventFragment,
} from "@generated/graphql";
import { IndexMap } from "@utils/indexMap";
import { useInterval } from "@utils/useInterval";
import { Container, Table } from "components/shared";
import { TT_DASH_SORT_ORDER } from "components/shared/AttendanceGrades/constants";
import { AttendanceDataProvider } from "contexts/AttendanceDataProvider";
import { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import { DEFAULT_TUTOR_DASHBOARD_REFETCH_INTERVAL } from "../../constants";
import { getDashboardEmptyContainer } from "../../helpers";
import { cohortNameCell } from "./cells/cohortNameCell";
import { engagementNameCell } from "./cells/engagementNameCell";
import { hostPresenceCell } from "./cells/hostPresenceCell";
import { statusCell } from "./cells/statusCell";
import { studentCountCell } from "./cells/studentCountCell";
import { teacherTutorCell } from "./cells/teacherTutorCell";
import { InstructionalSupportTabDashboardEventDetails } from "./types";
import { instructionalSupportTabDashboardEventDetailsBuilder } from "./utils";

const PAGE_SIZE = 15;

InstructionalSupportTab.fragments = {
  tutorDashboardEvent: gql`
    fragment InstructionalSupportTab_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      cohortId
      engagementId
      startDateTime
      ...StatusCell_TutorDashboardEvent
      ...CohortNameCell_TutorDashboardEvent
      ...HostPresenceCell_TutorDashboardEvent
      ...TeacherTutorCell_TutorDashboardEvent
      ...EngagementNameCell_TutorDashboardEvent
      ...InstructionalSupportTabDashboardEventDetailsBuilder_TutorDashboardEvent
    }
    ${instructionalSupportTabDashboardEventDetailsBuilder.fragments
      .tutorDashboardEvent}
    ${statusCell.fragments.tutorDashboardEvent}
    ${cohortNameCell.fragments.tutorDashboardEvent}
    ${teacherTutorCell.fragments.tutorDashboardEvent}
    ${hostPresenceCell.fragments.tutorDashboardEvent}
    ${engagementNameCell.fragments.tutorDashboardEvent}
  `,
  tutorDashboardCohort: gql`
    fragment InstructionalSupportTab_TutorDashboardCohort on TutorDashboardCohort {
      id
      ...StatusCell_TutorDashboardCohort
      ...CohortNameCell_TutorDashboardCohort
      ...StudentCountCell_TutorDashboardCohort
    }
    ${statusCell.fragments.tutorDashboardCohort}
    ${cohortNameCell.fragments.tutorDashboardCohort}
    ${studentCountCell.fragments.tutorDashboardCohort}
  `,
  tutorDashboardEngagement: gql`
    fragment InstructionalSupportTab_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      ...StatusCell_TutorDashboardEngagement
      ...HostPresenceCell_TutorDashboardEngagement
      ...EngagementNameCell_TutorDashboardEngagement
    }
    ${statusCell.fragments.tutorDashboardEngagement}
    ${hostPresenceCell.fragments.tutorDashboardEngagement}
    ${engagementNameCell.fragments.tutorDashboardEngagement}
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  InstructionalSupportTab_TutorDashboardEventFragment,
  InstructionalSupportTab_TutorDashboardCohortFragment,
  InstructionalSupportTab_TutorDashboardEngagementFragment
>;

type Props = {
  loading: boolean;
  cohorts: InstructionalSupportTab_TutorDashboardCohortFragment[];
  engagements: InstructionalSupportTab_TutorDashboardEngagementFragment[];
  isDashboardEvents: InstructionalSupportTab_TutorDashboardEventFragment[];
};

export function InstructionalSupportTab({
  cohorts,
  loading,
  engagements,
  isDashboardEvents,
}: Props) {
  const eventsLength = isDashboardEvents.length;
  const [pageIndex, setPageIndex] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => setPageIndex(0), [isDashboardEvents]);

  useInterval(() => {
    return setCurrentDate(new Date());
  }, DEFAULT_TUTOR_DASHBOARD_REFETCH_INTERVAL);

  const cohortsMap = useMemo(() => {
    return new IndexMap(cohorts, ({ id }) => id);
  }, [cohorts]);

  const engagementsMap = useMemo(() => {
    return new IndexMap(engagements, ({ id }) => id);
  }, [engagements]);

  const cohortRowData = useMemo<EventDetails[]>(() => {
    const sortedEvents = isDashboardEvents
      .map((dashboardEvent) =>
        instructionalSupportTabDashboardEventDetailsBuilder(
          dashboardEvent,
          cohortsMap.get(dashboardEvent.cohortId),
          engagementsMap.get(dashboardEvent.engagementId),
          currentDate
        )
      )
      .sort(
        (a, b) =>
          TT_DASH_SORT_ORDER.indexOf(a.scheduleStatus) -
          TT_DASH_SORT_ORDER.indexOf(b.scheduleStatus)
      );

    const startIndex = pageIndex * PAGE_SIZE;
    return sortedEvents.slice(startIndex, startIndex + PAGE_SIZE);
  }, [isDashboardEvents, currentDate, pageIndex, cohortsMap, engagementsMap]);

  return (
    <AttendanceDataProvider>
      <Container flex className="flex-center min-h-[150px]">
        {eventsLength > 0 && cohortRowData ? (
          <Table
            pageSize={PAGE_SIZE}
            loading={loading}
            className="min-h-0"
            columns={getColumns}
            data={cohortRowData}
            showPagination={true}
            pagination={{
              pageIndex,
              pageSize: PAGE_SIZE,
              totalRows: eventsLength,
              canPreviousPage: pageIndex !== 0,
              canNextPage: pageIndex < Math.ceil(eventsLength / PAGE_SIZE) - 1,
              gotoPage: setPageIndex,
            }}
            errorRowsIds={cohortRowData
              .filter((row) => row.teacherTutorName == "Unassigned")
              .map(({ id }) => id)}
          />
        ) : (
          getDashboardEmptyContainer(
            "an Instructional Support (IS) or substitute",
            loading
          )
        )}
      </Container>
    </AttendanceDataProvider>
  );
}

const getColumns: Column<EventDetails>[] = [
  cohortNameCell(),
  engagementNameCell(),
  teacherTutorCell(),
  studentCountCell(),
  hostPresenceCell(),
  statusCell(),
];
