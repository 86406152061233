import { gql } from "@apollo/client";
import { TeachersSummaryTabs_MentorWeeksItemFragment } from "@generated/graphql";
import { OptionsToggleButton } from "components/shared";
import { DashboardCard } from "components/shared/DataVisualization/DashboardCard";
import { useState } from "react";
import { TeachersTypeTab } from "sections/AdminDashboard/types";
import { useDebounce } from "use-debounce";
import { InstructionalSupportsSummaryTab } from "./components/tabs/InstructionalSupportsTab";
import { MentorsSummaryTab } from "./components/tabs/MentorsSummaryTab";

TeachersSummaryTabs.fragments = {
  mentorsWeeksDetails: gql`
    fragment TeachersSummaryTabs_MentorWeeksItem on MentorWeeksItem {
      ...MentorsSummaryTab_MentorWeeksItem
    }
    ${MentorsSummaryTab.fragments.mentorsWeeksDetails}
  `,
};

type Props = {
  data?: TeachersSummaryTabs_MentorWeeksItemFragment[];
  loading?: boolean;
  fetchMoreData?: () => void;
};

export function TeachersSummaryTabs({ data, loading, fetchMoreData }: Props) {
  const [teachersTab, setTeachersTab] = useState<number>(0);
  const [debouncedActiveTab] = useDebounce(teachersTab, 250);

  const getIndex = (value: TeachersTypeTab): number =>
    Object.values(TeachersTypeTab).indexOf(value);

  return (
    <div className="flex flex-col gap-y-4">
      <OptionsToggleButton
        options={Object.values(TeachersTypeTab)}
        setActiveTab={setTeachersTab}
        activeTab={teachersTab}
        titleClassName="text-lg font-semibold"
        activeTabColor="bg-slate-700"
        height="h-[48px]"
      />
      <DashboardCard className="p-4!">
        {debouncedActiveTab === getIndex(TeachersTypeTab.MTs) && (
          <MentorsSummaryTab
            items={data}
            loading={loading}
            dataName={"MTs with sessions scheduled this week"}
            emptyStateSubtitle={"No MTs with sessions scheduled this week."}
            fetchMoreData={fetchMoreData}
          />
        )}
        {debouncedActiveTab === getIndex(TeachersTypeTab.ISs) && (
          <InstructionalSupportsSummaryTab
            dataName={"ISs with sessions scheduled this week"}
            emptyStateSubtitle={"No ISs with sessions scheduled this week."}
          />
        )}
      </DashboardCard>
    </div>
  );
}
