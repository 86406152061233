import { gql, useLazyQuery } from "@apollo/client";
import {
  TeachersStudentAttendanceMatchesQuery,
  TeachersStudentAttendanceMatchesQueryVariables,
  TutorDashboardCohortSession,
  TutoringTab_TutorDashboardCohortFragment,
  TutoringTab_TutorDashboardEngagementFragment,
  TutoringTab_TutorDashboardEventFragment,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { IndexMap } from "@utils/indexMap";
import { useInterval } from "@utils/useInterval";
import { Container } from "components/shared";
import { TT_DASH_SORT_ORDER } from "components/shared/AttendanceGrades/constants";
import { StudentLiveParticipantMatches } from "components/shared/AttendanceGrades/types";
import { makeAttendanceKey } from "components/shared/AttendanceGrades/utils";
import { useTutorDashboardData } from "contexts/TutorDashboardDataProvider";
import { compact, isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DEFAULT_TUTOR_DASHBOARD_REFETCH_INTERVAL } from "../../constants";
import { getDashboardEmptyContainer } from "../../helpers";
import { TutorTabCohortEventPanel } from "./components/TutorTabCohortEventPanel";
import { TutorTabDashboardEventDetails } from "./types";
import { tutoringTabDashboardEventDetailsBuilder } from "./utils";

TutoringTab.fragments = {
  tutorDashboardEvent: gql`
    fragment TutoringTab_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      cohortId
      engagementId
      startDateTime
      cohortSession {
        id
      }
      ...TutoringTabDashboardEventDetailsBuilder_TutorDashboardEvent
      ...TutoringTabCohortEventPanel_TutorDashboardEvent
    }
    ${tutoringTabDashboardEventDetailsBuilder.fragments.tutorDashboardEvent}
    ${TutorTabCohortEventPanel.fragments.tutorDashboardEvent}
  `,
  tutorDashboardCohort: gql`
    fragment TutoringTab_TutorDashboardCohort on TutorDashboardCohort {
      id
      ...TutoringTabCohortEventPanel_TutorDashboardCohort
    }
    ${TutorTabCohortEventPanel.fragments.tutorDashboardCohort}
  `,
  tutorDashboardEngagement: gql`
    fragment TutoringTab_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      ...TutoringTabCohortEventPanel_TutorDashboardEngagement
    }
    ${TutorTabCohortEventPanel.fragments.tutorDashboardEngagement}
  `,
};

export const GET_TEACHERS_LIVE_PARTICIPANT_MATCHES = gql`
  query TeachersStudentAttendanceMatches($cohortSessionIds: [ID!]!) {
    teachersStudentAttendanceMatches(cohortSessionIds: $cohortSessionIds) {
      match
      cohortSessionId
      student {
        id
        fullName
      }
      distances {
        distance
        participant
      }
    }
  }
`;

type EventDetails = TutorTabDashboardEventDetails<
  TutoringTab_TutorDashboardEventFragment,
  TutoringTab_TutorDashboardCohortFragment,
  TutoringTab_TutorDashboardEngagementFragment
>;

type Props = {
  loading: boolean;
  cohorts: TutoringTab_TutorDashboardCohortFragment[];
  engagements: TutoringTab_TutorDashboardEngagementFragment[];
  ttDashboardEvents: TutoringTab_TutorDashboardEventFragment[];
};

export function TutoringTab({
  cohorts,
  loading,
  engagements,
  ttDashboardEvents,
}: Props) {
  const { studentMatchData, setStudentMatchData } = useTutorDashboardData();
  const [currentDate, setCurrentDate] = useState(new Date());

  useInterval(() => {
    return setCurrentDate(new Date());
  }, DEFAULT_TUTOR_DASHBOARD_REFETCH_INTERVAL);

  const cohortsMap = useMemo(() => {
    return new IndexMap(cohorts, ({ id }) => id);
  }, [cohorts]);

  const engagementsMap = useMemo(() => {
    return new IndexMap(engagements, ({ id }) => id);
  }, [engagements]);

  const events = useMemo<EventDetails[]>(
    () =>
      ttDashboardEvents
        .map((dashboardEvent) =>
          tutoringTabDashboardEventDetailsBuilder(
            dashboardEvent,
            cohortsMap.get(dashboardEvent.cohortId),
            engagementsMap.get(dashboardEvent.engagementId),
            currentDate
          )
        )
        .sort(
          (a, b) =>
            TT_DASH_SORT_ORDER.indexOf(a.scheduleStatus) -
            TT_DASH_SORT_ORDER.indexOf(b.scheduleStatus)
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ttDashboardEvents, currentDate]
  );

  const cohortSessionIds = useMemo<TutorDashboardCohortSession["id"][]>(
    () => compact(ttDashboardEvents.map((event) => event.cohortSession?.id)),
    [ttDashboardEvents]
  );

  const [getLiveParticipantStudentMatches] = useLazyQuery<
    TeachersStudentAttendanceMatchesQuery,
    TeachersStudentAttendanceMatchesQueryVariables
  >(GET_TEACHERS_LIVE_PARTICIPANT_MATCHES, {
    variables: { cohortSessionIds },
    fetchPolicy: "network-only",
    onError: (error) =>
      fetchErrToast(
        "this Cohort Sessions student live participant matches",
        error
      ),
    onCompleted: (data) => {
      if (!data?.teachersStudentAttendanceMatches) return;
      const matches: StudentLiveParticipantMatches = {};
      data.teachersStudentAttendanceMatches.forEach((match) => {
        matches[makeAttendanceKey(match.cohortSessionId, match.student.id)] = {
          match: match.match ?? "",
          distances: match.distances,
          studentId: match.student.id,
          studentFullName: match.student.fullName ?? "",
        };
      });
      if (!isEqual(matches, studentMatchData)) setStudentMatchData(matches);
    },
  });

  useEffect(() => {
    getLiveParticipantStudentMatches();
    const interval = setInterval(() => {
      getLiveParticipantStudentMatches();
    }, DEFAULT_TUTOR_DASHBOARD_REFETCH_INTERVAL);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading || events.length === 0 ? (
    <Container flex className="flex-center min-h-[150px]">
      {getDashboardEmptyContainer("a Teacher-Tutor (TT)", loading)}
    </Container>
  ) : (
    events.map((eventDetails, i) => (
      <TutorTabCohortEventPanel
        zIndex={events.length - i}
        key={eventDetails.cacheKey}
        eventDetails={eventDetails}
      />
    ))
  );
}
