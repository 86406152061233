import { gql } from "@apollo/client";
import { useLayout } from "@contexts/LayoutProvider";
import { Button, Container, Icon, Tooltip } from "@shared";
import { SessionStudentEvaluationTable } from "@shared/AttendanceGrades/components/SessionStudentEvaluationTable";
import { CohortInfoLine } from "@shared/CohortInfoLine";
import { EmptyStateContainer } from "@shared/EmptyStateContainer";
import { JoinMeetingButton } from "@shared/JoinMeetingButton";
import {
  getSessionButtonStyles,
  OpenSessionReportButton,
} from "@shared/SessionReports";
import { ReportSubmitLocation } from "@shared/SessionReports/components/ReportSubmitButton";
import clsx from "clsx";
import { CohortDetailsOpenIcon } from "components/cohorts/CohortDetailsModal/components/CohortDetailsOpenIcon";
import { useMemo } from "react";
import { EventTimeDetails } from "sections/UserDashboard/components/EventTimeDetails";
import { EventDetails } from "../types";
import { getAttendedCount, getStudentEvaluationDetails } from "../utils";

TutorTabCohortEventPanel.fragments = {
  tutorDashboardEvent: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      subSubject
      endDateTime
      durationMinutes
      mentorTeacherName
      cohortSession {
        id
        sessionReportId
        sessionReportHasConcerns
        sessionReportStudentPerformanceCount
      }
      ...GetStudentEvaluationDetails_TutorDashboardEvent
    }
    ${getStudentEvaluationDetails.fragments.tutorDashboardEvent}
  `,
  tutorDashboardCohort: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardCohort on TutorDashboardCohort {
      id
      name
      instructionLevel
      publicReferenceId
      activeStudentsCount
      ...GetStudentEvaluationDetails_TutorDashboardCohort
    }
    ${getStudentEvaluationDetails.fragments.tutorDashboardCohort}
  `,
  tutorDashboardEngagement: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      name
      productType
      ...GetStudentEvaluationDetails_TutorDashboardEngagement
    }
    ${getStudentEvaluationDetails.fragments.tutorDashboardEngagement}
  `,
};

type Props = {
  zIndex: number;
  eventDetails: EventDetails;
};

export function TutorTabCohortEventPanel({ zIndex, eventDetails }: Props) {
  const { isLessThanXlScreen } = useLayout();
  const { TutorDashboard } = ReportSubmitLocation;

  const {
    cohort,
    subject,
    subSubject,
    engagement,
    startDateTime,
    cohortSession,
    cohortSessionId,
    mentorTeacherName,
  } = eventDetails;

  const { studentEvaluationRows, evaluationDetails } = useMemo(() => {
    return getStudentEvaluationDetails(eventDetails);
  }, [eventDetails]);

  const { studentAttendanceEntries } = cohortSession || {};
  const { isCohortNoShowDay, isEngagementNoShowDay } = evaluationDetails;
  const hasAttendanceRecords = (studentAttendanceEntries ?? []).length > 0;
  const { sessionReportId, sessionReportHasConcerns } = cohortSession ?? {};

  const reportPerformanceComplete = useMemo(() => {
    const { sessionReportStudentPerformanceCount } = cohortSession ?? {};
    const attendedCount = getAttendedCount(studentAttendanceEntries ?? []);
    const sessionCount = sessionReportStudentPerformanceCount ?? 0;
    return attendedCount <= sessionCount;
  }, [cohortSession, studentAttendanceEntries]);

  if (!engagement || !cohort) return null;

  const hasStudents = cohort.activeStudentsCount > 0;
  const hasNoData = !hasStudents || !hasAttendanceRecords;
  const isNoShowDay = isCohortNoShowDay || isEngagementNoShowDay;
  const reportIsComplete = !!sessionReportId && reportPerformanceComplete;

  return (
    <Container
      zIndex={zIndex}
      key={eventDetails.cacheKey}
      className="flex flex-col gap-2 w-full overflow-visible z-auto"
    >
      <EventTimeDetails
        endTime={eventDetails.endDateTime}
        status={eventDetails.scheduleStatus}
        startTime={eventDetails.startDateTime}
        minutesElapsed={eventDetails.minutesElapsed}
        conclusion="This cohort session is now over."
        durationMinutes={eventDetails.durationMinutes}
        minutesRemaining={eventDetails.minutesRemaining}
      />
      <div
        className={clsx(
          "flex flex-col lg:flex-row lg:justify-between lg:items-center",
          "relative overflow-visible gap-2"
        )}
      >
        <CohortInfoLine
          cohortId={cohort?.id}
          cohortName={cohort?.name}
          engagementId={engagement?.id}
          engagementName={engagement?.name}
          mentorTeacherName={mentorTeacherName}
          productType={engagement?.productType}
          subjectCombos={[{ subject, subSubject }]}
          instructionLevel={cohort?.instructionLevel}
        />

        <div className="flex items-center gap-x-2 mb-1 w-full min-h-8 lg:w-fit justify-end">
          <OpenSessionReportButton
            hideUntilSessionStarts
            submitLocation={TutorDashboard}
            cohortSessionId={cohortSessionId}
            hideButton={hasNoData || isNoShowDay}
            cohortSessionReportId={sessionReportId}
            hasConcerns={sessionReportHasConcerns ?? false}
            className={getSessionButtonStyles(reportIsComplete)}
            cohortSessionStartTime={new Date(startDateTime).getTime()}
          />

          <JoinMeetingButton isHost={true} cohortId={cohort?.id}>
            <Tooltip disabled={!isLessThanXlScreen} content="Join Meeting">
              <Button height="xs">
                <Icon
                  icon="zoom"
                  color="text-white"
                  className={clsx(
                    "w-[16px] h-[16px]",
                    isLessThanXlScreen ? "mx-[3px]" : ""
                  )}
                />
                {!isLessThanXlScreen && (
                  <div className="flex h-full items-center text-white text-sm ml-[6px] mr-[6px]">
                    Join Meeting
                  </div>
                )}
              </Button>
            </Tooltip>
          </JoinMeetingButton>

          <CohortDetailsOpenIcon className="-ml-1 pr-1" cohortId={cohort.id} />
        </div>
      </div>

      {hasStudents ? (
        <SessionStudentEvaluationTable
          evaluationDetails={evaluationDetails}
          studentEvaluationRows={studentEvaluationRows}
        />
      ) : (
        <EmptyStateContainer
          title=""
          showIcon={false}
          className="gap-0 !pt-1 !pb-3"
          subtitle="This session has no active students."
        />
      )}
    </Container>
  );
}
