import { gql } from "@apollo/client";
import { useLayout } from "@contexts/LayoutProvider";
import {
  StatusCell_TutorDashboardCohortFragment,
  StatusCell_TutorDashboardEngagementFragment,
  StatusCell_TutorDashboardEventFragment,
} from "@generated/graphql";
import { JoinMeetingButton } from "@shared/JoinMeetingButton";
import { OpenSessionReportButton } from "@shared/SessionReports";
import { ReportSubmitLocation } from "@shared/SessionReports/components/ReportSubmitButton";
import clsx from "clsx";
import { Button, Icon, Tooltip } from "components/shared";
import {
  format,
  formatDuration,
  intervalToDuration,
  isToday,
  isTomorrow,
  isYesterday,
} from "date-fns";
import { CellProps, Column } from "react-table";
import { EventScheduleStatus } from "types/events";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

statusCell.fragments = {
  tutorDashboardEvent: gql`
    fragment StatusCell_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      endDateTime
      startDateTime
      durationMinutes
      cohortSession {
        id
        sessionReportId
        sessionReportHasConcerns
      }
    }
  `,
  tutorDashboardCohort: gql`
    fragment StatusCell_TutorDashboardCohort on TutorDashboardCohort {
      id
      publicReferenceId
    }
  `,
  tutorDashboardEngagement: gql`
    fragment StatusCell_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      videoProvider
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  StatusCell_TutorDashboardEventFragment,
  StatusCell_TutorDashboardCohortFragment,
  StatusCell_TutorDashboardEngagementFragment
>;

export function statusCell<D extends EventDetails>(): Column<D> {
  return {
    id: "statusCell",
    Header: "Status",
    Cell: ({
      row: {
        original: {
          cohort,
          endDateTime,
          startDateTime,
          cohortSession,
          scheduleStatus,
          minutesElapsed,
          durationMinutes,
          minutesRemaining,
        },
      },
    }: CellProps<D>) => {
      const { isLessThan2XlScreen } = useLayout();
      const { TutorDashboard } = ReportSubmitLocation;
      const isPast = scheduleStatus === EventScheduleStatus.CONCLUDED;
      const isOngoing = scheduleStatus === EventScheduleStatus.ONGOING;
      const isUpcoming = scheduleStatus === EventScheduleStatus.UPCOMING;

      const sessionEndDateTime = new Date(endDateTime);
      const sessionStartDateTime = new Date(startDateTime);
      const startTimeFormatted = format(sessionStartDateTime, "h:mm a");
      const endTimeFormatted = format(sessionEndDateTime, "h:mm	a");

      const timeUntilStart = intervalToDuration({
        start: new Date(),
        end: startDateTime,
      });

      const formattedTimeUntilStart = formatDuration(timeUntilStart, {
        format: ["hours", "minutes"],
      });

      const getRelativeDayName = isToday(startDateTime)
        ? "Today"
        : isYesterday(startDateTime)
        ? "Yesterday"
        : isTomorrow(startDateTime)
        ? "Tomorrow"
        : format(startDateTime, "EEEE, MM/dd/yyyy");

      const { id, sessionReportId, sessionReportHasConcerns } =
        cohortSession || {};

      return (
        <div className="flex flex-col justify-between items-center gap-x-1 lg:flex-row">
          <div className="flex flex-col text-sm font-medium gap-1">
            <div className="relative flex flex-row items-center w-auto gap-3">
              {isOngoing && (
                <div className="absolute top-[4px] left-[-18px] w-3 h-3 rounded-full bg-rose-500 animate-bounce" />
              )}
              <div
                className={clsx(
                  "font-bold",
                  isOngoing && "text-rose-500",
                  isPast && "text-gray-800",
                  isUpcoming && "text-indigo-500"
                )}
              >
                {isOngoing &&
                  `Ongoing, ${minutesElapsed} minutes elapsed; ${minutesRemaining} remaining`}
                {isPast && "This session is now over."}
                {isUpcoming && `Upcoming in ${formattedTimeUntilStart}`}
              </div>
            </div>
            {`${startTimeFormatted} - ${endTimeFormatted} (${durationMinutes} min) ${getRelativeDayName}`}
          </div>
          <div
            className={clsx(
              "flex flex-col justify-center items-end gap-y-1.5 -my-1",
              isLessThan2XlScreen ? "w-10" : "w-[130px]"
            )}
          >
            <JoinMeetingButton isHost={true} cohortId={cohort?.id}>
              <Tooltip
                tooltipProps={{ place: "left" }}
                disabled={!isLessThan2XlScreen}
                content="Join Meeting"
              >
                <Button
                  className={clsx(isLessThan2XlScreen ? "w-9!" : "w-[154px]!")}
                  height="xs"
                >
                  <Icon
                    icon="zoom"
                    color="text-white"
                    className={clsx("w-[17px] h-[17px]")}
                  />
                  {!isLessThan2XlScreen && (
                    <div className="flex h-full items-center text-white text-sm ml-1.5 whitespace-nowrap">
                      Join Meeting
                    </div>
                  )}
                </Button>
              </Tooltip>
            </JoinMeetingButton>

            <OpenSessionReportButton
              cohortSessionId={id}
              submitLocation={TutorDashboard}
              showButtonAsOnlyIcon={isLessThan2XlScreen}
              hasConcerns={sessionReportHasConcerns ?? false}
              cohortSessionReportId={sessionReportId ?? null}
              cohortSessionStartTime={sessionStartDateTime.getTime()}
            />
          </div>
        </div>
      );
    },
  };
}
