import { gql, useQuery } from "@apollo/client";
import {
  GetCurrentUserQuery,
  TutorDashboardDataQuery,
  TutorDashboardDataQueryVariables,
} from "@generated/graphql";
import { ISODate, MINUTE_MS, normalizeToZonedISODate } from "@utils/dateTime";
import { fetchErrToast } from "@utils/errorLogging";
import { useInterval } from "@utils/useInterval";
import clsx from "clsx";
import { SIDEBAR_WIDTH } from "components/auth/AuthedLayout";
import { OptionsToggleButton } from "components/shared/Buttons/OptionsToggleButton";
import { useLayout } from "contexts/LayoutProvider";
import "react";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { DayDatePicker } from "../components/DayDatePicker";
import { InstructionalSupportTab } from "./components/InstructionalSupportTab/InstructionalSupportTab";
import { TutoringTab } from "./components/TutoringTab/TutoringTab";

export enum TutorDashTab {
  TT = "Tutoring",
  IS = "Instructional Support",
}

const GET_TUTOR_DASHBOARD_DATA_QUERY = gql`
  query TutorDashboardData($userId: ID!, $selectedISODayET: String!) {
    getTutorDashboardData(
      userId: $userId
      selectedISODayET: $selectedISODayET
    ) {
      tutoringTabEvents {
        cacheKey
        ...TutoringTab_TutorDashboardEvent
      }
      instructionalSupportTabEvents {
        cacheKey
        ...InstructionalSupportTab_TutorDashboardEvent
      }
      cohorts {
        id
        ...TutoringTab_TutorDashboardCohort
        ...InstructionalSupportTab_TutorDashboardCohort
      }
      engagements {
        id
        ...TutoringTab_TutorDashboardEngagement
        ...InstructionalSupportTab_TutorDashboardEngagement
      }
      tutoringTabEventsCount
      instructionalSupportTabEventsCount
    }
  }
  ${TutoringTab.fragments.tutorDashboardEvent}
  ${TutoringTab.fragments.tutorDashboardCohort}
  ${TutoringTab.fragments.tutorDashboardEngagement}
  ${InstructionalSupportTab.fragments.tutorDashboardEvent}
  ${InstructionalSupportTab.fragments.tutorDashboardCohort}
  ${InstructionalSupportTab.fragments.tutorDashboardEngagement}
`;

type Props = {
  currentUser: NonNullable<GetCurrentUserQuery["currentUser"]>;
};

export function TutorDashboard({ currentUser }: Props) {
  const { isLessThanMdScreen } = useLayout();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [debouncedActiveTab] = useDebounce(activeTab, 250);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedISODayET, setSelectedISODayET] = useState<ISODate>(
    normalizeToZonedISODate(currentDate)
  );

  useInterval(() => setCurrentDate(new Date()), MINUTE_MS);

  const { data, loading } = useQuery<
    TutorDashboardDataQuery,
    TutorDashboardDataQueryVariables
  >(GET_TUTOR_DASHBOARD_DATA_QUERY, {
    fetchPolicy: "cache-first",
    variables: { userId: currentUser.id, selectedISODayET },
    onError: (err) => fetchErrToast("this Dashboard data.", err),
  });

  const getIndex = (value: TutorDashTab): number =>
    Object.values(TutorDashTab).indexOf(value);

  const { getTutorDashboardData: dash } = data || {};
  const { cohorts, engagements, tutoringTabEvents } = dash || {};

  const counts = dash
    ? [dash.tutoringTabEventsCount, dash.instructionalSupportTabEventsCount]
    : [0, 0];

  return (
    <>
      <div
        className={clsx(
          "fixed flex justify-between lg:items-center flex-col -m-4",
          "lg:flex-row gap-x-4 gap-y-2 z-1000 h-[150px] lg:h-[90px] p-4",
          "bg-gray-200/40 backdrop-blur-xs"
        )}
        style={{
          width: `calc(100% - ${isLessThanMdScreen ? 0 : SIDEBAR_WIDTH}px)`,
        }}
      >
        <OptionsToggleButton
          counts={counts}
          loading={loading}
          activeTab={activeTab}
          options={Object.values(TutorDashTab)}
          setActiveTab={setActiveTab}
        />

        <DayDatePicker
          selectedISODayET={selectedISODayET}
          setSelectedISODayET={setSelectedISODayET}
        />
      </div>

      <div className="flex flex-col gap-y-4 mt-[135px] lg:mt-[75px]">
        {debouncedActiveTab === getIndex(TutorDashTab.TT) && (
          <TutoringTab
            loading={loading}
            cohorts={cohorts ?? []}
            engagements={engagements ?? []}
            ttDashboardEvents={tutoringTabEvents ?? []}
          />
        )}
        {debouncedActiveTab === getIndex(TutorDashTab.IS) && (
          <InstructionalSupportTab
            loading={loading}
            cohorts={cohorts ?? []}
            engagements={engagements ?? []}
            isDashboardEvents={dash?.instructionalSupportTabEvents ?? []}
          />
        )}
      </div>
    </>
  );
}
