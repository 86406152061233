import clsx from "clsx";
import { Icon } from "components/shared";
import {
  format,
  formatDuration,
  intervalToDuration,
  isToday,
  isTomorrow,
  isYesterday,
} from "date-fns";
import { EventScheduleStatus } from "types/events";

type Props = {
  status: EventScheduleStatus;
  minutesRemaining: number;
  durationMinutes: number;
  minutesElapsed: number;
  startTime: Date;
  endTime: Date;
  conclusion?: string;
};

export const EventTimeDetails = ({
  minutesRemaining,
  durationMinutes,
  minutesElapsed,
  conclusion,
  startTime,
  endTime,
  status,
}: Props) => {
  const isOngoing = status === EventScheduleStatus.ONGOING;
  const isUpcoming = status === EventScheduleStatus.UPCOMING;
  const isPast = status === EventScheduleStatus.CONCLUDED;
  const startTimeFormatted = format(startTime, "h:mm a");
  const endTimeFormatted = format(endTime, "h:mm	a");

  const timeUntilStart = intervalToDuration({
    start: new Date(),
    end: startTime,
  });

  const formattedTimeUntilStart = formatDuration(timeUntilStart, {
    format: ["days", "hours", "minutes"],
  });

  const getRelativeDayName = isToday(startTime)
    ? "Today"
    : isYesterday(startTime)
    ? "Yesterday"
    : isTomorrow(startTime)
    ? "Tomorrow"
    : format(startTime, "EEEE, MM/dd/yyyy");

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
      <div className="flex justify-start gap-3 items-center">
        <Icon icon="userGroup" size={6} color="text-slate-700" />
        <div className="font-bold text-base sm:text-xl">
          {`${startTimeFormatted} - ${endTimeFormatted} (${durationMinutes} min) ${getRelativeDayName}`}
        </div>
      </div>
      <div>
        <div className="flex flex-row gap-2 items-center">
          {isOngoing && (
            <div className="w-4 h-4 rounded-full bg-rose-500 animate-bounce"></div>
          )}
          <div
            className={clsx(
              "font-bold",
              isOngoing && "text-gray-700",
              isPast && "text-gray-500",
              isUpcoming && "text-indigo-500"
            )}
          >
            {isOngoing &&
              `Ongoing, ${minutesElapsed} minutes elapsed; ${minutesRemaining} remaining`}
            {isPast &&
              (conclusion ? conclusion : "These sessions are now over.")}
            {isUpcoming &&
              `Upcoming in ${
                formattedTimeUntilStart == ""
                  ? "< 1 minute"
                  : formattedTimeUntilStart
              }`}
          </div>
        </div>
      </div>
    </div>
  );
};
