import { gql } from "@apollo/client";
import { SpecialEventsSummary_SpecialEventDetailsFragment } from "@generated/graphql";
import {
  DashboardCard,
  DashboardSection,
} from "components/shared/DataVisualization";
import { HolidaySummaryTab, SpecialEventSummaryTab } from "./components";

SpecialEventsSummary.fragments = {
  specialEventsSummary: gql`
    fragment SpecialEventsSummary_SpecialEventDetails on SpecialEventDetails {
      ...HolidaySummaryTab_SpecialEventDetails
      ...SpecialEventSummaryTab_SpecialEventDetails
    }
    ${HolidaySummaryTab.fragments.holidayData}
    ${SpecialEventSummaryTab.fragments.holidayData}
  `,
};

type Props = { data?: SpecialEventsSummary_SpecialEventDetailsFragment };

export function SpecialEventsSummary({ data }: Props) {
  return (
    <DashboardSection title="Holidays and Special Events Summary">
      <DashboardCard className="p-4! gap-y-6">
        <HolidaySummaryTab
          organizationData={data?.byOrganization ?? []}
          holidayData={data?.usHolidays ?? []}
        />
        <SpecialEventSummaryTab engagementData={data?.byEngagement ?? []} />
      </DashboardCard>
    </DashboardSection>
  );
}
