import { gql } from "@apollo/client";
import { PaymentsSummary_PaymentsDetailsFragment } from "@generated/graphql";
import { getDateRangeFromET } from "@utils/dateTime";
import {
  DashboardCard,
  DashboardSection,
  ProgressBar,
} from "components/shared/DataVisualization";
import { useEffect, useState } from "react";
import { PaymentCell } from "./components";

PaymentsSummary.fragments = {
  paymentsSummary: gql`
    fragment PaymentsSummary_PaymentsDetails on PaymentsDetails {
      instructionalSupportAccruedTotal
      instructionalSupportPreviousPayPeriodTotal
      tutorTeacherAccruedTotal
      tutorTeacherPreviousPayPeriodTotal
      accruedTotal
      previousPayPeriodTotal
      payPeriodStartDate
      payPeriodEndDate
      previousPayPeriodStartDate
      previousPayPeriodEndDate
      daysProgress
    }
  `,
};

type HoveredCard =
  | "instructionalSupportPayments"
  | "tutorTeacherPayments"
  | "totalPayments"
  | "none";

type Props = { data?: PaymentsSummary_PaymentsDetailsFragment };

export function PaymentsSummary({ data }: Props) {
  const [animate, setAnimate] = useState(true);
  const [hoveredCard, setHoveredCard] = useState<HoveredCard>("none");

  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 10);
    return () => clearTimeout(timeout);
  }, []);

  const getNum = (num?: number) =>
    animate ? 0 : num ? Math.round(num * 100) / 100 : 0;

  const payPeriodString = data
    ? getDateRangeFromET(
        new Date(data.payPeriodStartDate),
        new Date(data.payPeriodEndDate)
      )
    : "";

  const previousPayPeriodString = data
    ? getDateRangeFromET(
        new Date(data.previousPayPeriodStartDate),
        new Date(data.previousPayPeriodEndDate)
      )
    : "";

  const payPeriodDetails = payPeriodString ? (
    <div className="flex text-sm font-semibold text-slate-600 gap-x-1">
      Pay Period: {payPeriodString}
    </div>
  ) : null;

  return (
    <DashboardSection title="Payments Summary" titleDetails={payPeriodDetails}>
      <div className="grid grid-cols-12 gap-4 lg:gap-6">
        <DashboardCard
          gridWidth="third"
          mouseEnter={() => setHoveredCard("instructionalSupportPayments")}
          mouseLeave={() => setHoveredCard("none")}
          className="p-4!"
        >
          <div className="w-full h-full flex flex-col cursor-pointer gap-y-4">
            <PaymentCell
              title="IS Accrued"
              previousPayPeriodString={previousPayPeriodString}
              count={getNum(data?.instructionalSupportAccruedTotal ?? 0)}
              previousCount={getNum(
                data?.instructionalSupportPreviousPayPeriodTotal ?? 0
              )}
            />
            <ProgressBar
              progress={data?.daysProgress ?? 0}
              showIndicators={hoveredCard === "instructionalSupportPayments"}
            />
          </div>
        </DashboardCard>
        <DashboardCard
          gridWidth="third"
          mouseEnter={() => setHoveredCard("tutorTeacherPayments")}
          mouseLeave={() => setHoveredCard("none")}
          className="p-4!"
        >
          <div className="w-full h-full flex flex-col cursor-pointer gap-y-4">
            <PaymentCell
              title="TT Accrued"
              previousPayPeriodString={previousPayPeriodString}
              count={getNum(data?.tutorTeacherAccruedTotal ?? 0)}
              previousCount={getNum(
                data?.tutorTeacherPreviousPayPeriodTotal ?? 0
              )}
            />
            <ProgressBar
              progress={data?.daysProgress ?? 0}
              showIndicators={hoveredCard === "tutorTeacherPayments"}
            />
          </div>
        </DashboardCard>
        <DashboardCard
          gridWidth="lastThird"
          mouseEnter={() => setHoveredCard("totalPayments")}
          mouseLeave={() => setHoveredCard("none")}
          className="p-4!"
        >
          <div className="w-full h-full flex flex-col cursor-pointer gap-y-4">
            <PaymentCell
              title="Total Payments"
              previousPayPeriodString={previousPayPeriodString}
              count={getNum(data?.accruedTotal ?? 0)}
              previousCount={getNum(data?.previousPayPeriodTotal ?? 0)}
            />
            <ProgressBar
              progress={data?.daysProgress ?? 0}
              showIndicators={hoveredCard === "totalPayments"}
            />
          </div>
        </DashboardCard>
      </div>
    </DashboardSection>
  );
}
