import { gql } from "@apollo/client";
import {
  TeacherSummary_TeacherWeekDetailsFragment,
  TeachersSummary_MentorWeeksDetailsFragment,
} from "@generated/graphql";
import {
  DashboardCard,
  DashboardNumber,
  DashboardSection,
  DashboardTitle,
  ProgressBar,
} from "components/shared/DataVisualization";
import { useEffect, useState } from "react";
import { TeachersSummaryTabs } from "./teachersSummary/TeachersSummaryTabs";

TeachersSummary.fragments = {
  teachersSummary: gql`
    fragment TeacherSummary_TeacherWeekDetails on TeacherDetails {
      weeksMentorsCount
      weeksTutorsCount
      weeksInstructionalSupportCount
      weeksMentorsTutoringHoursCount
      weeksMentorsTutoringHoursCompleted
      weeksTutorsTutoringHoursCount
      weeksTutorsTutoringHoursCompleted
      weeksInstructionalSupportTutoringHoursCount
      weeksInstructionalSupportTutoringHoursCompleted
    }
  `,
  mentorsWeeksDetails: gql`
    fragment TeachersSummary_MentorWeeksDetails on MentorWeeksItem {
      ...TeachersSummaryTabs_MentorWeeksItem
    }
    ${TeachersSummaryTabs.fragments.mentorsWeeksDetails}
  `,
};

type HoveredCard =
  | "mentorsHours"
  | "instructionalSupportHours"
  | "teacherTutorsHours"
  | "none";

type Props = {
  teacherWeeksDetails?: TeacherSummary_TeacherWeekDetailsFragment;
  mentorsWeeksDetails?: TeachersSummary_MentorWeeksDetailsFragment[];
  viewingPast: boolean;
  loading?: boolean;
  fetchMoreData?: () => void;
};

export function TeachersSummary({
  teacherWeeksDetails,
  mentorsWeeksDetails,
  viewingPast,
  loading,
  fetchMoreData,
}: Props) {
  const [animate, setAnimate] = useState(true);
  const [hoveredCard, setHoveredCard] = useState<HoveredCard>("none");

  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 10);
    return () => clearTimeout(timeout);
  }, []);

  const getNum = (num?: number) => (animate ? 0 : num ? Math.round(num) : 0);

  const mentorsTutoringHoursCompleted = getNum(
    teacherWeeksDetails?.weeksMentorsTutoringHoursCompleted
      ? (teacherWeeksDetails.weeksMentorsTutoringHoursCompleted /
          teacherWeeksDetails.weeksMentorsTutoringHoursCount) *
          100
      : 0
  );

  const instructionalSupportTutoringHoursCompleted = getNum(
    teacherWeeksDetails?.weeksInstructionalSupportTutoringHoursCompleted
      ? (teacherWeeksDetails.weeksInstructionalSupportTutoringHoursCompleted /
          teacherWeeksDetails.weeksInstructionalSupportTutoringHoursCount) *
          100
      : 0
  );

  const tutorsTutoringHoursCompleted = getNum(
    teacherWeeksDetails?.weeksTutorsTutoringHoursCompleted
      ? (teacherWeeksDetails.weeksTutorsTutoringHoursCompleted /
          teacherWeeksDetails.weeksTutorsTutoringHoursCount) *
          100
      : 0
  );

  return (
    <DashboardSection title="Teachers Summary">
      <div className="grid grid-cols-12 gap-4 lg:gap-6">
        <DashboardCard
          gridWidth="third"
          mouseEnter={() => setHoveredCard("mentorsHours")}
          mouseLeave={() => setHoveredCard("none")}
          className="p-6!"
        >
          <div className="w-full h-full flex justify-between items-center cursor-pointer mb-2">
            <div className="flex flex-col items-center gap-y-2 w-1/2 justify-center">
              <DashboardNumber
                numberValue={getNum(teacherWeeksDetails?.weeksMentorsCount)}
              />
              <DashboardTitle
                title="MTs"
                place="top"
                tooltip="Number of mentors with scheduled sessions this week"
              />
            </div>
            <div className="flex flex-col items-center gap-y-2 w-1/2 justify-center">
              <DashboardNumber
                numberValue={getNum(
                  teacherWeeksDetails?.weeksMentorsTutoringHoursCount
                )}
              />
              <DashboardTitle
                title="Hours"
                place="top"
                tooltip={
                  <div className="flex flex-col">
                    <span>Number of mentors hours scheduled this week</span>
                    {`(${teacherWeeksDetails?.weeksMentorsTutoringHoursCompleted} completed)`}
                  </div>
                }
              />
            </div>
          </div>
          <ProgressBar
            progress={viewingPast ? 100 : mentorsTutoringHoursCompleted}
            showIndicators={hoveredCard === "mentorsHours"}
          />
        </DashboardCard>

        <DashboardCard
          gridWidth="third"
          mouseEnter={() => setHoveredCard("instructionalSupportHours")}
          mouseLeave={() => setHoveredCard("none")}
          className="p-6!"
        >
          <div className="w-full h-full flex justify-between items-center cursor-pointer mb-2">
            <div className="flex flex-col items-center gap-y-2 w-1/2 justify-center">
              <DashboardNumber
                numberValue={getNum(
                  teacherWeeksDetails?.weeksInstructionalSupportCount
                )}
              />
              <DashboardTitle
                title="ISs"
                place="top"
                tooltip="Number of instructional supports with scheduled sessions this week"
              />
            </div>
            <div className="flex flex-col items-center gap-y-2 w-1/2 justify-center">
              <DashboardNumber
                numberValue={getNum(
                  teacherWeeksDetails?.weeksInstructionalSupportTutoringHoursCount
                )}
              />
              <DashboardTitle
                title="Hours"
                place="top"
                tooltip={
                  <div className="flex flex-col">
                    <span>
                      Number of instructional support hours scheduled this week
                    </span>
                    {`(${teacherWeeksDetails?.weeksInstructionalSupportTutoringHoursCompleted} completed)`}
                  </div>
                }
              />
            </div>
          </div>
          <ProgressBar
            progress={
              viewingPast ? 100 : instructionalSupportTutoringHoursCompleted
            }
            showIndicators={hoveredCard === "instructionalSupportHours"}
          />
        </DashboardCard>
        <DashboardCard
          gridWidth="lastThird"
          mouseEnter={() => setHoveredCard("teacherTutorsHours")}
          mouseLeave={() => setHoveredCard("none")}
          className="p-6!"
        >
          <div className="w-full h-full flex justify-between items-center cursor-pointer mb-2">
            <div className="flex flex-col items-center gap-y-2 w-1/2 justify-center">
              <DashboardNumber
                numberValue={getNum(teacherWeeksDetails?.weeksTutorsCount)}
              />
              <DashboardTitle
                title="TTs"
                place="top"
                tooltip="Number of tutors with scheduled sessions this week"
              />
            </div>
            <div className="flex flex-col items-center gap-y-2 w-1/2 justify-center">
              <DashboardNumber
                numberValue={getNum(
                  teacherWeeksDetails?.weeksTutorsTutoringHoursCount
                )}
              />
              <DashboardTitle
                title="Hours"
                place="top"
                tooltip={
                  <div className="flex flex-col">
                    <span>
                      Number of Teacher-Tutor hours scheduled this week
                    </span>
                    {`(${teacherWeeksDetails?.weeksTutorsTutoringHoursCompleted} completed)`}
                  </div>
                }
              />
            </div>
          </div>
          <ProgressBar
            progress={viewingPast ? 100 : tutorsTutoringHoursCompleted}
            showIndicators={hoveredCard === "teacherTutorsHours"}
          />
        </DashboardCard>
      </div>
      <TeachersSummaryTabs
        data={mentorsWeeksDetails}
        loading={loading}
        fetchMoreData={fetchMoreData}
      />
    </DashboardSection>
  );
}
