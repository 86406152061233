import { gql } from "@apollo/client";
import {
  Engagement,
  MentorDashAssignmentRole,
  RosterRole,
  StaffCell_SessionAssignmentRowFragment,
} from "@generated/graphql";
import { AttendanceScorecardModal } from "@shared/AttendanceGrades/components";
import { getUserPronounsText } from "@utils/labels";
import clsx from "clsx";
import { AddInfractionModal, Icon, Tooltip } from "components/shared";
import { useState } from "react";

StaffCell.fragments = {
  sessionAssignmentRow: gql`
    fragment StaffCell_SessionAssignmentRow on MentorDashSessionAssignmentRow {
      cohortId
      staffName
      staffRole
      staffUserId
      staffPronouns
    }
  `,
};

type Props = {
  colWidth: number;
  engagementId: Engagement["id"];
  row: StaffCell_SessionAssignmentRowFragment;
};

export function StaffCell({
  engagementId,
  colWidth,
  row: { cohortId, staffName, staffRole, staffUserId, staffPronouns },
}: Props) {
  const [showScorecardModal, setShowScorecardModal] = useState(false);
  const [showInfractionModal, setShowInfractionModal] = useState(false);

  return (
    <>
      <div
        className={clsx(
          "flex items-center justify-between gap-2 group py-2 pl-3 pr-1"
        )}
        style={{ width: `${colWidth}px` }}
      >
        <div className="flex items-center gap-1">
          <p className="text-gray-900 text-sm font-semibold">{staffName}</p>
          <p className="font-medium text-indigo-700 text-[10px] leading-none">
            {getUserPronounsText(staffPronouns, "", true)}
          </p>
        </div>

        {!!staffUserId && (
          <div className="flex items-center gap-[6px] mr-7">
            <Tooltip
              tooltipProps={{ place: "right" }}
              content={`View Teacher Scorecard - ${staffName}`}
            >
              <div
                className="h-6 w-6 flex flex-center cursor-pointer rounded-full bg-blue-700"
                onClick={() => setShowScorecardModal(true)}
              >
                <Icon icon="source" color="text-white" size={4} />
              </div>
            </Tooltip>

            <Tooltip
              tooltipProps={{ place: "right" }}
              content={`Document Teacher Infraction - ${staffName}`}
            >
              <div
                className="h-6 w-6 flex flex-center cursor-pointer rounded-full bg-red-500"
                onClick={() => setShowInfractionModal(true)}
              >
                <Icon icon="infraction" color="text-white" size={4} />
              </div>
            </Tooltip>
          </div>
        )}
      </div>

      {!!staffUserId && (
        <>
          <AttendanceScorecardModal
            userId={staffUserId}
            show={showScorecardModal}
            closeModal={() => setShowScorecardModal(false)}
          />

          <AddInfractionModal
            show={showInfractionModal}
            engagementId={engagementId}
            teacherId={staffUserId}
            teacherName={staffName}
            cohortId={cohortId || undefined}
            role={
              staffRole === MentorDashAssignmentRole.Tt
                ? RosterRole.TutorTeacher
                : RosterRole.SubstituteTeacher
            }
            closeModal={() => setShowInfractionModal(false)}
          />
        </>
      )}
    </>
  );
}
